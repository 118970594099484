import { stripRichTextWrapperTag } from '~/utils/html';
import { marketLinks, productLinks } from '~/utils/fetch-links';
import { sanitizeModule } from '../featured-block/adapter';

const adapter = async (
  { primary },
  data,
  { $i18n, $prismic, $enhancedLinkSerializer }
) => {
  const moduldeId = primary.featured_block_default.id;
  const defaultModule =
    moduldeId &&
    (await $prismic.client.getByID(moduldeId, {
      fetchLinks: [...marketLinks, ...productLinks],
    }));

  const defaultModuleData = defaultModule?.data;
  const defaultModuleDataEntries = defaultModuleData?.featured_block_entries;

  if (!defaultModuleDataEntries) return null;

  const defaultModuleProps = {
    title: stripRichTextWrapperTag(defaultModuleData?.featured_block_title),
    fixed: defaultModuleData?.featured_block_fixed_cards_width,
    type: defaultModuleData?.featured_block_type,
    cards: defaultModuleDataEntries
      ? sanitizeModule(defaultModuleDataEntries, $i18n, $enhancedLinkSerializer)
      : [],
  };

  return defaultModuleProps;
};

export default adapter;
